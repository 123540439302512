<template>
  <web-app v-if="isNavigatorClient"></web-app>
  <web-pc v-else></web-pc>
</template>

<script setup>
import webPc from "./client/webPc.vue";
import webApp from "./client/webApp.vue";

import { computed, ref, getCurrentInstance } from "vue";

const { proxy } = getCurrentInstance();

const isNavigatorClient = computed(() => proxy.$isNavigatorClient());
</script>
