<template>
  <div class="case-container mobile-wrap">
    <!-- 盒子1 -->
    <div class="wrapper-box wrapper-box-1">
      <div class="describe-box is-wrapper-box">
        <div class="row row-1 fontRegular">云效科技-软件开发服务商</div>

        <div class="row row-2 fontBold">多行业 众品牌 海量案例</div>

        <div class="row-3">
          <div class="btn-wrap">
            <div class="btn" @click="jumpFormScrollTop">
              <button class="fontRegular">联系我们</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 盒子2 -->
    <div class="wrapper-box wrapper-box-2">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-title">
          <h2 class="fontBold">经典案例</h2>
          <div class="eng-explain fontRegular">CLASSIC CASE</div>
          <div class="sub-title fontRegular">
            真实项目案例，从细节里透露行业精益求精的专业，每一个高水准的创意，呈现出云效科技开发团队的智慧和力量
          </div>
        </div>

        <div class="wrapp-group-box">
          <list-card
            :src="item.icon"
            :name="item.name"
            :backgroundColor="item.backgroundColor"
            :routeQuery="item.routeQuery"
            v-for="(item, index) in wrappBox2__list1"
            :key="'box-3__item-1' + index"
            :keyIndex="index"
            class="addPadding"
            :isPointer="false"
            :isExpandName="true"
            :showFreeTrialTag="item.showFreeTrialTag"
          ></list-card>
        </div>
      </div>
    </div>

    <!-- 盒子7 -->
    <div class="wrapper-box wrapper-box-3">
      <div class="wrapper-content is-wrapper-box">
        <div class="wrapp-group-box">
          <!-- 表单 -->
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, getCurrentInstance } from "vue";
import datajs from "../data.js";
import anime from "animejs";

const { proxy } = getCurrentInstance();

// 辅助参数
const { wrappBox2__list1 } = datajs.apply(proxy);

const { mainStore, dataStore } = proxy.$usePiniaModule();

//  定位底部表单
const jumpFormScrollTop = () => {
  const call = () => {
    const scrollTop =
      document.getElementsByClassName("footer-container")[0].offsetTop;
    window.scrollTo({ top: scrollTop });
  };

  // 操作鼠标
  dataStore.setGlobalClickRecord(function () {
    document
      .getElementsByClassName("wrapper-box-1")[0]
      .getElementsByClassName("btn")[0]
      .click();
  });
  call();
};

// 设置一些动画
const setPageSomeAnimation = () => {
  anime
    .timeline({
      direction: "reverse",
      easing: "easeInOutSine",
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-3",
      translateX: 140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-2",
      translateX: -140,
      opacity: 0,
    })
    .add({
      duration: 300,
      targets: ".wrapper-box-1 .describe-box .row-1",
      translateX: 140,
      opacity: 0,
    });
};

onMounted(() => {
  setPageSomeAnimation();
});
</script>

<style lang="scss">
.case-container {
  &.mobile-wrap {
    .wrapper-box {
      .wrapp-title {
        text-align: center;
        h2 {
          font-size: 20px;
          color: rgba(51, 51, 51, 1);
        }
        .eng-explain {
          color: #999999;
          font-size: 12px;
          margin: 7px 0;
        }
        .sub-title {
          font-size: 12px;
          color: #333333;
          line-height: 18px;
        }
      }

      .wrapper-content {
        .list-more {
          margin-top: 80px;
          text-align: center;
          img {
            margin: auto;
            width: 160px;
            height: 48px;
          }
        }
      }

      // 盒子1
      &-1 {
        background-image: url(../../../assets/images/list-case/mobile_banner.png);
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: 0px 63px;
        background-size: auto 470px;

        .describe-box {
          margin: 0 auto;
          padding-top: 93px;
          padding-bottom: 204px;
          position: relative;
          .row {
            text-align: center;
            color: rgba(51, 51, 51, 1);
            &-1 {
              font-size: 18px;
            }
            &-2 {
              font-weight: bold;
              font-size: 30px;
              margin: 20px 0;
            }

            &-3 {
              .btn-wrap {
                .btn {
                  display: flex;
                  justify-content: center;

                  button {
                    width: 212px;
                    height: 63px;
                    font-size: 18px;
                    background: #0a41ff;
                    border: none;
                    color: #fff;
                    border-radius: 2px;

                    &:active {
                      background-color: #0a41ffcf;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // 盒子2
      &-2 {
        .wrapper-content {
          padding: 30px 20px;
          margin: auto;

          .wrapp-title {
            .sub-title {
              width: 240px;
              margin: auto;
            }
          }

          .wrapp-group-box {
            .list-card-container {
              padding: 0;
              padding-top: 24px;

              .card-item {
                .title {
                  margin-top: 10px;
                }
              }
            }

            .group-item {
              padding: 37px;
              .item {
                font-size: 14px;
                .image {
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
                .title {
                  min-width: 30px;
                  width: 30px;
                  height: 30px;
                  border-radius: 30px;
                  color: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: 0 auto;
                  margin-top: 30px;
                  transition: width 600ms, color 200ms;
                }

                &:hover {
                  .title {
                    width: 130px;
                    color: white;
                    transition: width 200ms, color 600ms;
                    border-radius: 20px;
                  }
                }
              }
            }
          }
        }
      }

      &-3 {
        background-image: url(../../../assets/images/home-top-bg-3.png);
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: auto 450px;
        padding: 1px 0;
        .wrapper-content {
        }
      }
    }
  }
}
</style>
