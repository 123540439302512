export default function () {
  /* 盒子2 */
  const wrappBox2__list1 = [
    {
      icon: require("@/assets/images/project-list-card/project-32.png"),
      name: "商机小程序",
      showFreeTrialTag: true,
      backgroundColor: "rgba(38, 157, 255, 1)",
      routeQuery: { name: "businessOportunity" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-33.png"),
      name: "内部招商系统",
      showFreeTrialTag: true,
      backgroundColor: "rgba(115, 192, 151, 1)",
      routeQuery: { name: "attractInvestment" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-34.png"),
      name: "B2B2C 购物商城",
      showFreeTrialTag: true,
      backgroundColor: "#FF8686",
      routeQuery: { name: "B2B2CShoppingMall" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-2.png"),
      name: "Ampere系统",
      showFreeTrialTag: true,
      backgroundColor: "rgba(71, 183, 192, 1)",
      routeQuery: { name: "intelligentDevices" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-7.png"),
      name: "喜餐小程序",
      backgroundColor: "rgba(245, 113, 71, 1)",
      routeQuery: { name: "happyMeal" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-3.png"),
      name: "智能AR眼睛",
      showFreeTrialTag: true,
      backgroundColor: "rgba(54, 188, 248, 1)",
      routeQuery: { name: "smartArGlasses" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-5.png"),
      name: "南天司法系统",
      showFreeTrialTag: true,
      backgroundColor: "rgba(43, 115, 207, 1)",
      routeQuery: { name: "nantianJudicial" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-9.png"),
      name: "光汇云油APP",
      backgroundColor: "rgba(251, 202, 88, 1)",
      routeQuery: { name: "guanghuiCloudOil" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-4.png"),
      name: "云宠智能",
      showFreeTrialTag: true,
      backgroundColor: "#2F6FFD",
      routeQuery: { name: "cloudPetIntelligence" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-11.png"),
      name: "掌中影APP",
      backgroundColor: "rgba(2, 84, 207, 1)",
      showFreeTrialTag: true,
      routeQuery: { name: "blueNet" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-6.png"),
      name: "中国人民银行",
      backgroundColor: "rgba(254, 0, 2, 1)",
      routeQuery: { name: "zhBankingFinance" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-8.png"),
      name: "东深运维",
      backgroundColor: "rgba(52, 131, 255, 1)",
      routeQuery: { name: "dongshenOperation" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-10.png"),
      name: "蔚然云木官网",
      backgroundColor: "rgba(131, 166, 39, 1)",
      routeQuery: { name: "weiranYunmu" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-1.png"),
      name: "获客e系统",
      showFreeTrialTag: true,
      backgroundColor: "rgba(247, 121, 58, 1)",
      routeQuery: { name: "customerAcquisitionFinance" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-13.png"),
      name: "深圳国际仲裁院",
      backgroundColor: "rgba(52, 124, 223, 1)",
      routeQuery: { name: "szArbitration" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-12.png"),
      name: "家庭储能系统",
      showFreeTrialTag: true,
      backgroundColor: "rgba(243, 158, 44, 1)",
      routeQuery: { name: "householdEnergyStorage" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-31.png"),
      name: "公安局智慧社区",
      backgroundColor: "rgba(143, 164, 246, 1)",
      routeQuery: { name: "intelligentDoorLock" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-25.png"),
      name: "中建钢构",
      backgroundColor: "rgba(255, 157, 106, 1)",
      routeQuery: { name: "cscecSteelStructure" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-14.png"),
      name: "十分到家",
      backgroundColor: "rgba(177, 164, 155, 1)",
      routeQuery: { name: "logisticsSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-20.png"),
      name: "检验联盟",
      backgroundColor: "rgba(0, 102, 204, 1)",
      routeQuery: { name: "inspectionAlliance" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-26.png"),
      name: "溯源系统",
      backgroundColor: "rgba(61, 174, 87, 1)",
      routeQuery: { name: "agriculturalProductSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-15.png"),
      name: "第二人民医院",
      backgroundColor: "rgba(127, 141, 247, 1)",
      routeQuery: { name: "oralManagementSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-21.png"),
      name: "音响租赁",
      backgroundColor: "rgba(240, 195, 50, 1)",
      routeQuery: { name: "audioRentalSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-16.png"),
      name: "病房视讯",
      backgroundColor: "rgba(18, 95, 206, 1)",
      routeQuery: { name: "wardVideoSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-27.png"),
      name: "服务器后台",
      backgroundColor: "rgba(243, 158, 44, 1)",
      routeQuery: { name: "smartBracelet" },
    },

    {
      icon: require("@/assets/images/project-list-card/project-19.png"),
      name: "BTOC商城",
      backgroundColor: "rgba(52, 142, 255, 1)",
      routeQuery: { name: "btocShoppingMall" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-22.png"),
      name: "共享充电宝",
      backgroundColor: "rgba(59, 204, 202, 1)",
      routeQuery: { name: "sharedPowerBank" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-28.png"),
      name: "艾必力健身",
      backgroundColor: "rgba(255, 179, 1, 1)",
      routeQuery: { name: "sharedGym" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-23.png"),
      name: "智能储物柜",
      backgroundColor: "rgba(67, 70, 87, 1)",
      routeQuery: { name: "intelligentLocker" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-29.png"),
      name: "智能配电",
      backgroundColor: "rgba(97, 65, 141, 1)",
      routeQuery: { name: "intelligentPowerDistribution" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-18.png"),
      name: "ASM预算系统",
      backgroundColor: "rgba(31, 172, 116, 1)",
      routeQuery: { name: "asmBudgetSystem" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-24.png"),
      name: "冠字号后台",
      backgroundColor: "rgba(89, 162, 252, 1)",
      routeQuery: { name: "crownSize" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-17.png"),
      name: "骆驼快充",
      backgroundColor: "rgba(200, 80, 108, 1)",
      routeQuery: { name: "newEnergy" },
    },
    {
      icon: require("@/assets/images/project-list-card/project-30.png"),
      name: "北线监控系统",
      backgroundColor: "rgba(127, 177, 255, 1)",
      routeQuery: { name: "northLineMonitoring" },
    },
  ];

  return {
    wrappBox2__list1,
  };
}
